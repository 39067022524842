export const months = [
  'jan',
  'feb',
  'maa',
  'apr',
  'mei',
  'jun',
  'jul',
  'aug',
  'sep',
  'okt',
  'nov',
  'dec',
];

export const days = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'];
